.break-sm {
  display: block;
  width: 100%;
  height: 0;
  visibility: hidden;

  @include media-breakpoint-up(sm) {
    display: inline;
    width: auto;
    height: auto;
    visibility: visible;
  }
}

.hide-sm {
  display: none;

  @include media-breakpoint-up(sm) {
    display: inherit;
  }
}
