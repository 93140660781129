.footer {
  display: block;
  position: relative;
  background: $color-primary-dark;
  padding: 3rem 0 0;
  color: $color-neutral-lighter;
  border-top: 2px solid $color-black;
  margin: 4rem 0 0;
  text-align: left;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background: $color-secondary;
    border-left: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &__item {
    padding: 0 0 1rem;

    @include media-breakpoint-up(sm) {
      padding: 0 0 2rem;
    }
  }

  .link {
    margin-right: 0.5em;
  }

  &__copyright {
    background: $color-primary-darker;
    padding: 0.5rem;
  }
}
