.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: $font-weight-base;
  text-align: center;
  color: $color-primary-darker;
  overflow-y: auto;

  background: $color-secondary;

  position: relative;
  z-index: 100;
  opacity: 0;
  transition: opacity 1s ease-in;

  &--hydrated,
  &.no-js {
    opacity: 1;
  }
}

.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
