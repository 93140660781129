.tickets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  justify-content: stretch;
  padding: $component-padding-medium;
  margin: 0 auto 1rem;
  width: 100%;

  &__ticket {
    background: $color-primary;
    text-shadow: $text-shadow-base;
    flex: 0 0 30%;
    margin: 0 auto;
    border-radius: $border-radius-base;
    color: $color-white;
    font-weight: bold;
    padding: 0.5rem;
    opacity: 0.7;
    transform: scale(1);
    transition: all 0.25s ease-in;

    &:last-child {
      opacity: 0.9;
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  &__title {
    display: block;
    font-size: 0.8em;
  }

  &__price {
    display: block;
    font-size: 1.75em;

    &--secondary {
      border-top: 1px solid #ccc;
      font-size: 1.2em;
    }
  }
}
