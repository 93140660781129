.impressions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 0.5rem;

  &__impression {
    flex: 1 1 45%;
    aspect-ratio: 3/2;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      flex: 1 1 28%;
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}
