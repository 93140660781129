.button {
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-base;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-family: 'BlackNo7', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  background-color: $color-neutral;
  border: 2px solid transparent;
  text-shadow: $text-shadow-base;
  transition: background 0.1s ease-in;
  padding: $component-padding-medium;
  border-radius: $border-radius-base;
  min-width: 120px;
  text-decoration: none;
  margin: 0.5rem;

  .bi {
    color: $color-white;
    margin-right: 0.5em;
  }

  &:hover,
  &:focus {
    color: $color-white;
    background: $color-neutral-dark;
    text-decoration: none;
  }

  &:active {
    color: $color-white;
    background: $color-neutral-darker;
  }

  &--primary {
    background-color: $color-primary;

    &:hover,
    &:focus {
      background: $color-primary-dark;
    }

    &:active {
      background: $color-primary-darker;
    }
  }

  &--secondary {
    background-color: $color-secondary;

    &:hover,
    &:focus {
      background: $color-secondary-dark;
    }

    &:active {
      background: $color-secondary-darker;
    }
  }

  &--small {
    font-size: 0.8rem;
    padding: $component-padding-small;
    margin: 0.25rem;
  }
}
