.logo {
  display: block;
  transform: rotate(-360deg) scale(0);
  transition: transform 0.5s ease-in;

  &__image {
    display: block;
    height: 150px;
    max-width: 100%;
    margin: 2rem auto;

    @include media-breakpoint-up(sm) {
      height: 250px;
    }
  }

  &--small &__image {
    height: 100px;
  }
}

.body--hydrated .logo {
  transform: rotate(0) scale(1);
}
