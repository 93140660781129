.sponsors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem 1rem;

  &__sponsor {
    display: flex;
    flex: 0 1 45%;
    align-content: center;

    @include media-breakpoint-up(sm) {
      flex: 0 1 31%;
    }
  }

  &__item {
    flex: 1 1 100%;
    aspect-ratio: 7/3;
    justify-content: center;
    align-content: center;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
