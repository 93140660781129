.lineup {
  display: block;
  text-align: left;

  &__day {
    display: block;
    margin: 0.5rem 0;

    &__title {
      padding: 5px;
      background: $color-black;
      color: $color-white;
      font-weight: bold;

      small {
        float: right;
        line-height: inherit;
      }

      > * {
        margin: 0;
      }
    }
  }

  &__band {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-bottom: 1px solid $color-neutral-light;

    p {
      margin: 0;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      background: $color-neutral-lighter;
    }

    &:last-child {
      border: 0;
    }

    &__time {
      flex: 0 0 20%;
      padding-right: 5px;
    }

    &__image-container {
      flex: 0 0 20%;
      height: 50px;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      padding-right: 10px;
    }

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      flex: 0 0 80%;
    }

    &__title {
      position: relative;

      .bi {
        color: inherit;
        position: absolute;
        right: -0.5rem;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__details {
      display: block;
      color: $color-neutral;
      padding: 2px 0;
      margin-top: 2px !important;
      border-top: 1px solid $color-neutral-light;
      height: auto;
      max-height: 500px;
      transition: all 0.5s ease-in;

      &--hidden {
        pointer-events: none;
        max-height: 0px;
        opacity: 0;
        margin-top: 0 !important;
      }
    }
  }
}
