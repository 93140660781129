a {
  color: $color-primary-dark;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-primary-darker;
    text-decoration: none;
  }

  &:active {
    color: $color-neutral-darker;
  }
}

.link {
  color: $color-primary-dark;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-primary-darker;
    text-decoration: none;
  }

  &:active {
    color: $color-neutral-darker;
  }

  &--inverted {
    color: $color-neutral-light;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-primary-light;
      text-decoration: none;
    }

    &:active {
      color: $color-neutral-light;
    }
  }
}
