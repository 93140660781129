.card {
  display: block;
  background: $color-white;
  padding: $component-padding-medium;
  color: $color-neutral-darker;
  border: 2px solid $color-black;
  border-radius: $border-radius-base;
  width: 600px;
  max-width: 100%;
  margin: 0 auto 1rem;

  @include media-breakpoint-up(sm) {
    margin: 0 auto 2rem;
    padding: $component-padding-large;
    box-shadow: $box-shadow-base;
  }

  &__header {
    margin: 0 0 1rem;
    border-bottom: 2px solid $color-black;
  }

  &--wide {
    width: 992px;
  }
}
