h1,
h2,
h3 {
  font-family: 'BlackNo7', sans-serif;
  font-weight: 700;
  margin: 0 0 0.5rem;
  line-height: 1.2;
  letter-spacing: 0.02em;
}

h1 {
  font-size: 3rem;
  text-shadow: $text-shadow-base;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}

p {
  margin: 0 0 0.5rem;
}

.small {
  font-size: 0.75em;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}
